<template>
  <div class="a25TenderConfig-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.tenderGuid"
            placeholder="所属标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
          <el-button type="primary" @click="handleCopyClick">复制</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="a25TenderConfigList"
      header-cell-class-name="bg-info text-light"
      :height="tableHeight"
      border
      >
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column prop="configName" header-align="center" align="center" label="配置项名称"></el-table-column>
      <el-table-column prop="configCode" header-align="center" align="center" label="配置项代码"></el-table-column>
      <el-table-column prop="parentID" header-align="center" align="center" label="父节点ID"></el-table-column>
      <el-table-column prop="configID" header-align="center" align="center" label="当前节点ID"></el-table-column>
      <el-table-column prop="amount" width="150" header-align="center" align="center" label="金额"></el-table-column>
      <el-table-column prop="amountDigits" width="90" header-align="center" align="center" label="金额位数"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column header-align="center" width="90" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <a25TenderConfig-form
        ref="a25TenderConfigForm"
        :a25TenderConfigModel="a25TenderConfigModel">
      </a25TenderConfig-form>
    </my-dialog>

    <my-dialog ref="copyDialog"
      title="复制标段配置"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleCopyOkClick">
      <el-form
        label-width="100px"
        >
        <el-form-item label="所属公司">
          <el-select
            v-model="copyModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in copyCompanys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目">
          <el-select
            v-model="copyModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in copyProjects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属标段">
          <el-select
            v-model="copyModel.tenderGuid"
            placeholder="所属标段"
            clearable>
            <el-option
              v-for="tender in copyTenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import A25TenderConfigForm from '@/views/A25TenderConfigManage/A25TenderConfigForm'
import A25TenderConfigModel from '@/model/A25TenderConfigModel'
import a25TenderConfigService from '@/services/a25TenderConfigService'
import companyService from '@/services/companyService'
import projectService from '@/services/projectService'
import tenderService from '@/services/tenderService'
import PageOperation from '@/mixins/PageOperation'
import GetTender from '@/mixins/GetTender'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  name: 'A25TenderConfigIndex',
  mixins: [
    GetTender,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    A25TenderConfigForm
  },
  data () {
    return {
      a25TenderConfigList: [],
      a25TenderConfigModel: new A25TenderConfigModel(),
      formDialogTitle: '',
      copyCompanys: [],
      copyProjects: [],
      copyTenders: [],
      searchModel: {
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      },
      copyModel: {
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.$set(this.searchModel, 'tenderGuid', '')
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.$set(this.searchModel, 'tenderGuid', '')
      this.tenders = []
      newVal && this._getTenders(newVal)
    },
    copyCompanyGuid (newVal) {
      this.$set(this.copyModel, 'projectGuid', '')
      this.$set(this.copyModel, 'tenderGuid', '')
      this.copyProjects = []
      this.copyTenders = []
      newVal && this._getCopyProjects(newVal)
    },
    copyProjectGuid (newVal) {
      this.$set(this.copyModel, 'tenderGuid', '')
      this.copyTenders = []
      newVal && this._getCopyTenders(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    },
    projectGuid () {
      return this.searchModel.projectGuid
    },
    copyCompanyGuid () {
      return this.copyModel.companyGuid
    },
    copyProjectGuid () {
      return this.copyModel.projectGuid
    }
  },
  methods: {
    handleCopyClick () {
      if (!this.searchModel.tenderGuid) {
        this.$alert('请选择要复制的标段！')
        return
      }
      this.$refs.copyDialog.open()
    },
    handleCopyOkClick () {
      this.$confirm('您确定要进行复制标段配置操作吗？')
        .then(() => {
          a25TenderConfigService.copyA25Config(this.searchModel.tenderGuid, this.copyModel.tenderGuid)
            .then(res => {
              this.$refs.copyDialog.close()
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
              } else {
                this.$message({
                  type: 'error',
                  message: '操作失败！'
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$refs.copyDialog.close()
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            })
        })
        .catch(() => {
          this.$refs.copyDialog.close()
          this.$message({
            type: 'warning',
            message: '您已经取消了操作！'
          })
        })
    },
    handleSearchClick () {
      this._getA25TenderConfigList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加配置信息'
      this.a25TenderConfigModel = new A25TenderConfigModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑配置信息'
      this.a25TenderConfigModel = new A25TenderConfigModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _a25TenderConfigModel = new A25TenderConfigModel(row)
          a25TenderConfigService.delete(_a25TenderConfigModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getA25TenderConfigList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.a25TenderConfigForm.validate()(valid => {
        if (valid) {
          if (!this.a25TenderConfigModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getA25TenderConfigList () {
      a25TenderConfigService.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.a25TenderConfigList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      a25TenderConfigService.add(this.a25TenderConfigModel)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getA25TenderConfigList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      a25TenderConfigService.edit(this.a25TenderConfigModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getA25TenderConfigList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _getCopyCompanys () {
      companyService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.copyCompanys = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getCopyTenders (projectGuid) {
      tenderService.list({}, { projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.copyTenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getCopyProjects (companyGuid) {
      projectService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'projects.sortId' }), { companyGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.copyProjects = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getCopyCompanys()
    this._getA25TenderConfigList()
  }
}
</script>
