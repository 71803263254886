<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="a25TenderConfigModel"
    label-width="110px"
    >
    <el-form-item label="配置项名称：" prop="configName">
      <el-input
        type="text"
        v-model="a25TenderConfigModel.configName">
      </el-input>
    </el-form-item>
    <el-form-item label="配置项代码：" prop="configCode">
      <el-input
        type="text"
        v-model="a25TenderConfigModel.configCode">
      </el-input>
    </el-form-item>
    <el-form-item label="配置项ID：" prop="configID">
      <el-input-number
        v-model.number="a25TenderConfigModel.configID"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="父节点ID：" prop="parentID">
      <el-input-number
        v-model.number="a25TenderConfigModel.parentID"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="类型：" prop="configType">
      <el-select v-model="a25TenderConfigModel.configType">
        <el-option label="合计值" :value="0"></el-option>
        <el-option label="清单项" :value="1"></el-option>
        <el-option label="无列表单项输入" :value="2"></el-option>
        <el-option label="有列表单项输入" :value="3"></el-option>
        <el-option label="无列表系统计算" :value="4"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="A25显示：" prop="a25IsDisplay">
      <el-switch
        v-model.number="a25TenderConfigModel.a25IsDisplay"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="显示"
        inactive-text="隐藏">
      </el-switch>
    </el-form-item>
    <el-form-item label="F02显示：" prop="f02IsDisplay">
      <el-switch
        v-model.number="a25TenderConfigModel.f02IsDisplay"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="显示"
        inactive-text="隐藏">
      </el-switch>
    </el-form-item>
    <template v-if="!a25TenderConfigModel.resourceId">
      <el-form-item label="所属公司：" prop="companyGuid">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属标段：" prop="tenderGuid">
        <el-select v-model="a25TenderConfigModel.tenderGuid" clearable>
          <el-option
            v-for="tender in tenders"
            :key="tender.tenderGuid"
            :label="tender.tenderName"
            :value="tender.tenderGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="所属标段：">
        <el-input
          disabled
          :value="a25TenderConfigModel.tenderName">
        </el-input>
      </el-form-item>
    </template>
    <el-form-item label="金额：" prop="amount">
      <el-input-number
        v-model="a25TenderConfigModel.amount"
        :step="1"
        :min="0"
        :precision="2"
        >
      </el-input-number>
    </el-form-item>
    <el-form-item label="金额位数：" prop="amountDigits">
      <el-input-number
        v-model="a25TenderConfigModel.amountDigits"
        :step="1"
        :min="0"
        :max="3"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="a25TenderConfigModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import A25TenderConfigModel from '@/model/A25TenderConfigModel'
import GetTender from '@/mixins/GetTender'

export default {
  name: 'A25TenderConfigForm',
  mixins: [
    GetTender
  ],
  props: {
    a25TenderConfigModel: {
      type: A25TenderConfigModel,
      default () {
        return new A25TenderConfigModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.projectGuid = ''
      this.a25TenderConfigModel.tenderGuid = ''
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.a25TenderConfigModel.tenderGuid = ''
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      projectGuid: '',
      rules: {
        configName: [
          {
            required: true,
            message: '请输入配置项名称',
            trigger: 'blur'
          }
        ],
        configCode: [
          {
            required: true,
            message: '请输入配置项代码',
            trigger: 'blur'
          }
        ],
        configType: [
          {
            required: true,
            message: '请输入类型',
            trigger: 'blur'
          }
        ],
        parentID: [
          {
            required: true,
            message: '请输入父节点',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择所属标段',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  },
  created () {
    console.log(this.a25TenderConfigModel)
  }
}
</script>
