import utility from '@/common/utility'
import auth from '@/common/auth'

export default class A25TenderConfigModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.a25ConfigGuid
      this.a25ConfigGuid = props.a25ConfigGuid
      this.configName = props.configName
      this.configCode = props.configCode
      this.configType = props.configType
      this.parentID = props.parentID
      this.tenderGuid = props.tenderGuid
      this.configID = props.configID
      this.a25IsDisplay = props.a25IsDisplay
      this.f02IsDisplay = props.f02IsDisplay
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.amount = props.amount
      this.amountDigits = props.amountDigits
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.a25ConfigGuid) this.a25ConfigGuid = ''
    if (!this.configName) this.configName = ''
    if (!this.configCode) this.configCode = ''
    if (!this.configType) this.configType = 0
    if (!this.parentID) this.parentID = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.configID) this.configID = 0
    if (!this.a25IsDisplay) this.a25IsDisplay = 0
    if (!this.f02IsDisplay) this.f02IsDisplay = 0
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.amount) this.amount = 0
    if (!this.amountDigits) this.amountDigits = 0
  }

  generatePrimaryKey () {
    this.a25ConfigGuid = utility.getUuid()
    this.resourceId = this.a25ConfigGuid
  }
}
