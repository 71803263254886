import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'a25TenderConfig'

let copyA25TenderConfigsByTenderUrl = 'CopyA25TenderConfigsByTender'

class A25TenderConfigService extends BaseService {
  copyA25Config (oldTenderGuid, newTenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, copyA25TenderConfigsByTenderUrl), {
      params: {
        oldTenderGuid,
        newTenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const a25TenderConfigService = new A25TenderConfigService(resourceName)

export default a25TenderConfigService
